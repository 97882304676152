import { lazy } from 'react';
import { sendUiEvent, useCanEdit } from '@/core';
import { PrimaryNav } from '@/domains/navs';
import {
  Badge,
  componentSpacing,
  Divider,
  Flex,
  IconButtonTertiary,
  TextBody,
} from '@knapsack/toby';
import { secondaryNavRootKey } from '@/utils/constants';
import { documentHeader, searchTriggerWrapper } from './document-header.css';
import { HomeLink } from '../home-link/home-link';

const AddNewTrigger = lazy(
  () => import('@/domains/navs/components/add-new/add-new-trigger'),
);

export const DocumentHeader = () => {
  const canEdit = useCanEdit();

  return (
    <header className={documentHeader} data-test-id="documentHeader">
      <HomeLink />
      {canEdit && (
        <>
          <AddNewTrigger
            menuPlacement="bottom-start"
            parentId={secondaryNavRootKey}
            testId="add-entity--trigger--navbar"
            trigger={{ type: 'button', buttonColor: 'inherit' }}
          />
          <Divider orientation="vertical" fixedSize="24px" spacing="xsmall" />
        </>
      )}
      <PrimaryNav />
      <div className={searchTriggerWrapper}>
        <IconButtonTertiary
          icon="search"
          size="medium"
          label="Search"
          color="inherit"
          onTrigger={() => {
            sendUiEvent({ type: 'commandBar.open' });
          }}
          tooltipContent={
            <Flex align="center" gap="xsmall">
              <TextBody
                size="small"
                spacing="none"
                className={componentSpacing({ marginRight: 'xsmall' })}
              >
                Search
              </TextBody>
              <Badge label="⌘" size="small" />
              <Badge label="K" size="small" />
            </Flex>
          }
        />
      </div>
    </header>
  );
};
