import { useState, useEffect, useMemo } from 'react';
import {
  getFlatDataFromTree,
  getTreeFromFlatData,
  TreeItem,
} from 'react-sortable-tree';
import { KnapsackNavItem } from '@knapsack/types';
import { sendAppClientDataEvent, useCanEdit } from '@/core';
import {
  useActiveTopNavItem,
  useNavItems,
  useParentNavIds,
  usePrivateNavIds,
} from '@/domains/navs/hooks';
import './secondary-nav.scss';
import { secondaryNavRootKey } from '@/utils/constants';
import clsx from 'clsx';
import { layoutSpacing } from '@knapsack/toby';
import { getNavItemByPathName } from '@/utils/nav';
import { usePathname } from 'next/navigation';
import { NavTree } from '../components/nav-tree/nav-tree';
import { setExpandedNavIds } from './secondary-nav.utils';

type KnapsackNavItemWithChildren = KnapsackNavItem & {
  children?: KnapsackNavItemWithChildren[];
  expanded?: boolean;
};

export const SecondaryNav = () => {
  const canEdit = useCanEdit();
  const activeTopNavItem = useActiveTopNavItem();
  const navItems = useNavItems({ includeHiddenItems: false });
  const privateNavIds = usePrivateNavIds();
  const pathname = usePathname();
  const activeNavItem = getNavItemByPathName({
    source: navItems,
    pathname,
  });
  const activeNavItemParentIds = useParentNavIds(activeNavItem?.id);

  const filteredNavItems = useMemo(() => {
    return navItems.filter((item) =>
      [item?.id, item?.parentId].every((id) => !privateNavIds.includes(id)),
    );
  }, [navItems, privateNavIds]);

  const [treeItems, setTreeItems] = useState<TreeItem[]>([]);
  const [allTreeItems, setAllTreeItems] = useState<TreeItem[]>([]);

  useEffect(() => {
    const initialTreeData = getTreeFromFlatData({
      rootKey: activeTopNavItem?.id,
      flatData: filteredNavItems,
      getKey: (item) => item?.id,
      getParentKey: (item) => item?.parentId,
    });

    const treeWithExpanded = setExpandedNavIds({
      items: JSON.parse(JSON.stringify(initialTreeData)),
      activeNavItemParentIds,
      activeNavItem,
    });
    setTreeItems(treeWithExpanded);
  }, [
    activeNavItem,
    activeNavItemParentIds,
    activeTopNavItem?.id,
    filteredNavItems,
    pathname,
  ]);

  useEffect(() => {
    const fullNavData = getTreeFromFlatData({
      rootKey: secondaryNavRootKey,
      flatData: navItems,
      getKey: (item) => item?.id,
      getParentKey: (item) => item?.parentId,
    });
    setAllTreeItems(fullNavData);
  }, [navItems]);

  return (
    <div
      className={clsx(
        'ks-test-sidebar',
        layoutSpacing({ paddingBottom: 'small' }),
      )}
    >
      <NavTree
        ariaLabel="Secondary"
        canEdit={canEdit}
        data-test-id="secondary-nav"
        handleNewTreeItems={(updatedTreeItems) => {
          if (updatedTreeItems && updatedTreeItems.length > 0) {
            setTreeItems(updatedTreeItems);
          }
        }}
        onMoveNode={({ treeData }) => {
          if (treeData && treeData.length > 0) {
            setTreeItems(treeData);
          }
          const allUpdatedNavItems = allTreeItems.map((item) => {
            if (item.id === activeTopNavItem?.id) {
              item.children = treeData;
            }
            return item;
          });

          const newFlatData = getFlatDataFromTree({
            treeData: allUpdatedNavItems,
            ignoreCollapsed: false,
            getNodeKey: (item: {
              treeIndex: number;
              node: KnapsackNavItemWithChildren;
            }) => {
              const nodeId = item?.node?.id;
              if (nodeId) return nodeId;
              console.error('Unable to find nav item id:', item);
              throw new Error(
                `Unable to find nav item id: "${JSON.stringify(item)}"`,
              );
            },
          }).map((flatDataItem) => {
            const { node, parentNode } = flatDataItem;
            const updatedNavItem: KnapsackNavItem = {
              id: node.id,
              name: node.name,
              path: node.path || '',
              parentId: parentNode ? parentNode.id : secondaryNavRootKey,
              hidden: node.hidden,
              minRoleNeeded: node.minRoleNeeded,
            };
            return updatedNavItem;
          });
          sendAppClientDataEvent({
            type: 'navs.secondary.update',
            payload: newFlatData,
          });
        }}
        treeItems={treeItems}
      />
    </div>
  );
};

export default SecondaryNav;
